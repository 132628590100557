import React from 'react'
import '../../assets/styles/recipe.css'

export default function RecipeRender(){
    return(
        <div className='recipe-component'>

        </div>
    )
}
